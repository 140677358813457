<template>
  <div>   
    <br>
     <b-row class="justify-content-center">
        <b-col cols="11" :md="user.perfil == 'Ejecutivo' ? 8 : 11" class="text-center">
            <center>
                <v-tabs  fixed-tabs  background-color="transparent" dark >
                    <v-tab   @click="tab = 0">
                       Ejecutivos
                    </v-tab>
                    <v-tab  v-if="user.perfil == 'Gerente' || user.perfil == 'Coordinador'" @click="tab = 1" style="font-size:17px;">
                       Gerente
                    </v-tab>
                    <v-tab  v-if="user.perfil == 'Coordinador'" @click="tab = 2" style="font-size:17px;">
                       Coordinador
                    </v-tab>
                </v-tabs>
            </center>
            <br><br>
            <center v-if="loader">
                <SpinnerLoader/>
                <p style="color:#fff;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>
            <br>
            <template v-if="tab == 0">
            <b-row class="justify-content-center">
                <b-col cols="11" md="5"></b-col>
                <b-col cols="8" md="2">
                  <input v-model="buscador_ejecutivo" type="text" class="form-control" placeholder="Buscar EJECUTIVO">
                </b-col>
                <b-col cols="2" md="5" class="text-left mt-2"> 
                     <u class="mt-4" style="color:#5cb617; cursor: pointer;" @click="regresarHome()">Regresar</u>
                </b-col>
              </b-row>
              <br>
                <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                    <br v-if="!isMobile">
                    <b-card-text class="text-center">
                        <div class="table-responsive">
                            <table class="table table-borderless mt-3 text-center">
                                <thead class="text-white">
                                <tr>
                                    <td :style="isMobile ? '' : 'width:5%;'">LUGAR</td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile">EJECUTIVO</td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td :style="isMobile ? '' : 'width:20%;'"><template v-if="!isMobile">EJECUTIVO</template></td>
                                    <!-- <td v-if="isMobile"></td>
                                    <td v-if="isMobile">IDPDV</td>
                                    <td :style="isMobile ? ' ' : 'width:12%;'"><template v-if="!isMobile">IDPDV</template></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile">REGIÓN</td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td :style="isMobile ? ' ' : 'width:12%;'"><template v-if="!isMobile">REGIÓN</template></td>
                                    <td>PLAZA</td> -->
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile">CUMPLIMIENTO</td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                    <td v-if="isMobile"></td>
                                     <td v-if="isMobile"></td>
                                    <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                    <!-- <td>DESAFÍOS</td> -->                                
                                    <td :style="isMobile ? ' ' : 'width:5%;'">PUNTOS</td>
                                    <td :style="isMobile ? ' ' : 'width:1%;'" v-if="!isMobile"></td>
                                </tr>
                                </thead>
                                <tbody>
                                <br>
                                <tr v-for="(ran, index) in ejecutivo" :key="ran.id">
                                    <td >
                                    <template v-if="!buscador_ejecutivo">
                                        <img v-if="index < 3" :src="'medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:40%; margin-top:-4px;' : 'width:80%; margin-top:2px;' ">  
                                        <br v-if="index < 3"> 
                                        <h4 v-if="index > 2" style="color: #01aae0 ;" :class="isMobile ? 'mt-4' : 'mt-1'">
                                        {{index + 1  }}
                                        </h4>  
                                        <br>
                                    </template>
                                    <template v-else>
                                    <img v-if="ran.lugar < 4" :src="'medalla/'+ (ran.lugar - 1) + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:30%; margin-top:-10px;' : 'width:80%; margin-top:2px;' ">   
                                           <br v-if="ran.lugar < 4"> 
                                        <h4 v-if="ran.lugar > 3" style="color: #01aae0 ;" :class="isMobile ? 'mt-2' : 'mt-1'">
                                        {{ran.lugar}}
                                        </h4>  
                                        <br>
                                    </template>
                                    </td>
                                    <td :colspan="isMobile ? '8' : ''">
                                        <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                        : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                            <b style="font-size:13px;">
                                                {{ran.ejecutivo}} 
                                            </b>
                                        </div>  
                                    </td>
                                   <!--  <td :colspan="isMobile ? '3' : ''">
                                        <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                        : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                            <b style="font-size:13px;">
                                                {{ran.idpdv}}
                                            </b>
                                        </div>  
                                    </td>
                                    <td :colspan="isMobile ? '6' : ''">
                                        <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                        : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                            <b style="font-size:13px;">
                                                {{ran.region}}
                                            </b>
                                        </div>  
                                    </td> -->
                                    <!-- <td  >
                                        <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d; '
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                            <b style="font-size:13px;">
                                                {{ran.plaza}}
                                            </b>
                                        </div>  
                                    </td> -->
                                    <td :colspan="isMobile ? '16' : ''">
                                        <div :style="ran.lugar < 4 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 

                                        <!-- <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'" >
                                            <br v-if="isMobile">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-35px;' : 'margin-top:-20px'">
                                                    <b-col cols="12" md="4" v-for="log in ran.logro" :key="log.id">
                                                        <template v-if="!isMobile">
                                                            <em style="text-transform:capitalize;" v-if="index == 0">{{log.tipo}}</em><br>
                                                        </template>   
                                                        <template v-else>
                                                            <em style="text-transform:capitalize;">{{log.tipo}}</em><br>
                                                        </template>                                                 
                                                        <h4 style="color:#fff;" class="mt-2">
                                                            {{parseInt(log.mayo) + parseInt(log.junio)}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div>  -->
                                    </td>
                                    <!-- <td>
                                        <h4 style="color:#fff;" class="mt-1">
                                            {{ran.puntos}}
                                        </h4>
                                    </td> -->
                                    <td >
                                        <div :style=" ran.lugar < 4 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                        : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2 ' : 'flexbox'">
                                            <b style="font-size:26px;">
                                                {{ran.total}}
                                            </b>
                                        </div>  
                                    </td>
                                    <td  v-if="!isMobile"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div> 
                    </b-card-text>
                    <br v-if="!isMobile">
                </b-card>
                <hr><br>         
            </template>
            <template v-if="tab == 1 && user.perfil == 'Gerente' || tab == 1 && user.perfil == 'Coordinador'">
              <b-row class="justify-content-center">
                <b-col cols="11" md="5"></b-col>
                <b-col cols="8" md="2">
                  <input v-model="buscar_gerente" type="text" class="form-control" placeholder="Buscar IDPDV">
                </b-col>
                <b-col cols="2" md="5" class="text-left mt-2"> 
                     <u class="mt-4" style="color:#5cb617; cursor: pointer;" @click="regresarHome()">Regresar</u>
                </b-col>
              </b-row>
              <br>
                <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                    <br v-if="!isMobile">
                    <b-card-text class="text-center">
                     <div class="table-responsive">
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td>LUGAR</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile">GERENTE</td> 
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">GERENTE</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">IDPDV</td>
                                <td :style="isMobile ? ' ' : 'width:12%;'"><template v-if="!isMobile">IDPDV</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:12%;'"><template v-if="!isMobile">REGIÓN</template></td>
                                <td>PLAZA</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">CUMPLIMIENTO</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                <!-- <td>DESAFÍOS</td> -->                                
                                <td :style="isMobile ? ' ' : 'width:12%;'">PUNTOS</td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in gerente" :key="ran.id">
                                <td>
                                 <template v-if="!buscar_gerente">
                                    <img v-if="index < 3" :src="'medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:50%; margin-top:4px;' : 'width:80%; margin-top:2px;' ">   
                                    <br v-if="index < 3"> 
                                    <h4 v-if="index > 2" style="color: #01aae0 ;" :class="isMobile ? 'mt-4' : 'mt-1'">
                                      {{index + 1  }}
                                    </h4>  
                                    <br>
                                  </template>
                                  <template v-else>
                                   <img v-if="ran.lugar < 4" :src="'medalla/'+ (ran.lugar - 1) + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:45%; margin-top:4px;' : 'width:80%; margin-top:2px;' ">   
                                         <br v-if="ran.lugar < 4"> 
                                        <h4 v-if="ran.lugar > 3" style="color: #01aae0 ;" :class="isMobile ? 'mt-2' : 'mt-1'">
                                        {{ran.lugar}}
                                        </h4>  
                                    <br>
                                  </template>
                                </td>
                                <td :colspan="isMobile ? '12' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                    : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.gerente}} 
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '3' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.idpdv}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '6' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.region}}
                                        </b>
                                    </div>  
                                </td>
                                <td>
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d; '
                                         : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.plaza}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '16' : ''">
                                       <div :style="ran.lugar < 4 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                </td>
                                <td>
                                    <div :style=" ran.lugar < 4 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:26px;">
                                            {{ran.total}}
                                        </b>
                                    </div>  
                                </td>
                            </tr>
                            </tbody>
                        </table>
                     </div>            
               
                    </b-card-text>
                    <br v-if="!isMobile">
                </b-card>
                <hr><br>
            </template>
            <template v-if="tab == 2 && user.perfil == 'Coordinador'">
              <b-row class="justify-content-center">
                <b-col cols="11" md="5"></b-col>
                <b-col cols="8" md="2">
                  <input v-model="buscar_coordinador" type="text" class="form-control" placeholder="Buscar coordinador">
                </b-col>
                <b-col cols="2" md="5" class="text-left mt-2"> 
                     <u class="mt-4" style="color:#5cb617; cursor: pointer;" @click="regresarHome()">Regresar</u>
                </b-col>
              </b-row>
              <br>
                <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                    <br v-if="!isMobile">
                    <b-card-text class="text-center">
                      <div class="table-responsive">
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td>LUGAR</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">COORDINADOR</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td><template v-if="!isMobile">COORDINADOR</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:12%;'"><template v-if="!isMobile">REGIÓN</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">CUMPLIMIENTO</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                <!-- <td>DESAFÍOS</td> -->                                
                                <td :style="isMobile ? ' ' : 'width:12%;'">PUNTOS</td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in coordinador" :key="ran.id">
                                <td>
                                  <template v-if="!buscar_coordinador">
                                    <img v-if="index < 3" :src="'medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;' ">   
                                    <br v-if="index < 3"> 
                                    <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-4">
                                      {{index + 1  }}
                                    </h4>  
                                    <br>
                                  </template>
                                  <template v-else>
                                   <img v-if="ran.lugar < 4" :src="'medalla/'+ (ran.lugar - 1) + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:19%; margin-top:-8px;' : 'width:80%; margin-top:2px;' ">   
                                         <br v-if="ran.lugar < 4"> 
                                        <h4 v-if="ran.lugar > 3" style="color: #01aae0 ;" :class="isMobile ? 'mt-2' : 'mt-1'">
                                        {{ran.lugar}}
                                        </h4>  
                                    <br>
                                  </template>
                                </td>
                                <td :colspan="isMobile ? '6' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                    : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.coordinador}} 
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '6' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.region}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '16' : ''">
                                       <div :style="ran.lugar < 4 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                    <!-- <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                        <b style="font-size:12px;">
                                            <br v-if="isMobile">
                                            <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-35px;' : 'margin-top:-20px'">
                                                <b-col cols="12" md="4" v-for="log in ran.logro" :key="log.id">
                                                    <template v-if="!isMobile">
                                                         <em style="text-transform:capitalize;" v-if="index == 0">{{log.tipo}}</em><br>
                                                    </template>   
                                                    <template v-else>
                                                         <em style="text-transform:capitalize;">{{log.tipo}}</em><br>
                                                    </template> 
                                                    <h4 style="color: #fff;" class="mt-2">
                                                        {{parseInt(log.mayo) + parseInt(log.junio)}}
                                                    </h4>
                                                </b-col>
                                            </b-row>
                                        </b>
                                    </div>  -->
                                </td>
                                <!-- <td>
                                    <h4 style="color:#fff;" class="mt-1">
                                        {{ran.puntos}}
                                    </h4>
                                </td> -->
                                <td>
                                    <div :style=" ran.lugar < 4 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:26px;">
                                            {{ran.total}}
                                        </b>
                                    </div>  
                                </td>
                            </tr>
                            </tbody>
                        </table>
                      </div>  
                    </b-card-text>
                    <br v-if="!isMobile">
                </b-card>
                <hr><br>
            </template>
        </b-col>
     </b-row>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}
import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Propio',
 components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayGerente:[],
      arrayEjecutivo:[],
      arrayCoordinador:[],
      tab:0,
      colors:'#83c32d',
      buscar_coordinador:"",
      buscar_gerente:"",
      buscador_ejecutivo:""
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    coordinador(){
     if (this.buscar_coordinador == '') {          
            return this.arrayCoordinador;
        }else{
               return   this.arrayCoordinador.filter(item => {
                    return   item.coordinador?.toLowerCase().includes(this.buscar_coordinador.toLowerCase())
                    ;
              });
        }
    },
    gerente(){
     if (this.buscar_gerente == '') {          
            return this.arrayGerente;
        }else{
               return   this.arrayGerente.filter(item => {
                    return   item.idpdv?.toLowerCase().includes(this.buscar_gerente.toLowerCase())
                    ;
              });
        }
    },
    ejecutivo(){
     if (this.buscador_ejecutivo == '') {          
            return this.arrayEjecutivo;
        }else{
               return   this.arrayEjecutivo.filter(item => {
                    return   item.ejecutivo?.toLowerCase().includes(this.buscador_ejecutivo.toLowerCase())
                    ;
              });
        }
    }
  },
  methods:{
    getcampanas(){
      this.loader = true;
      var url= 'ranking/propio';
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayGerente = data.gerente.sort(compare);
                this.arrayEjecutivo = data.ejecutivo.sort(compare);
                this.arrayCoordinador = data.coordinador.sort(compare);
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas();
  }
}
</script>
<style>
.flexbox {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}
</style>