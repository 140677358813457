<template>
  <div>   
    <template v-if="!isMobile"><br><br> <br><br><br><br><br><br></template><br>
    <center>
      <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : ''">
      <br><br>
      <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="regresarHome()" >Regresar</u>
      <br>
   
     <template v-if="isMobile"><br><br> <br></template>
     <!-- <img src="elementos/maleta.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : ''"> -->
    </center>
    <template v-if="user.canal == 2">
      <tlmkt></tlmkt>
    </template>
    <template v-if="user.canal == 3">
      <propio></propio>
    </template>
   <template v-if="user.canal == 1">
      <retail></retail>
    </template>
  </div>
</template>

<script>
import propio from './Propio.vue';
import tlmkt from './Tlmkt.vue';
import retail from './Retail.vue';
export default {
  name: 'Ranking',
  components:{
    tlmkt,
    propio,
    retail
  },
  data(){
    return{
      isMobile: this.$mobile(),
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
       this.$bus.$emit("cambia_fondo", true)
  }
}
</script>